// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-journal-index-js": () => import("./../../../src/pages/journal/index.js" /* webpackChunkName: "component---src-pages-journal-index-js" */),
  "component---src-pages-photography-js": () => import("./../../../src/pages/photography.js" /* webpackChunkName: "component---src-pages-photography-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-work-alumni-u-of-m-js": () => import("./../../../src/pages/work/alumni-u-of-m.js" /* webpackChunkName: "component---src-pages-work-alumni-u-of-m-js" */),
  "component---src-pages-work-graphql-blueprint-js": () => import("./../../../src/pages/work/graphql-blueprint.js" /* webpackChunkName: "component---src-pages-work-graphql-blueprint-js" */),
  "component---src-pages-work-index-js": () => import("./../../../src/pages/work/index.js" /* webpackChunkName: "component---src-pages-work-index-js" */),
  "component---src-pages-work-playvs-js": () => import("./../../../src/pages/work/playvs.js" /* webpackChunkName: "component---src-pages-work-playvs-js" */)
}

